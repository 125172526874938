import { rem } from 'polished'
import styled from 'styled-components'
import { display, background } from 'styled-system'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SearchIcon } from 'components/Icons'
import { Eyebrow } from 'components/Typography'
import { appearingAnimation } from 'styles/animation'
import theme from 'styles/theme'

const animationProps = `
  animation-direction: normal;
  animation-duration: 1.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.54,0,.15,1);
`

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: ${theme.space[3]};
  margin: 0 auto;
  min-height: 400px;
  height: 150vw;
  width: 100%;
  max-height: 540px;

  ${(props) => !props.hasImage && `background-color: ${theme.colors.coaldark};`}

  ${(props) => (props.condensed ? `height: 540px;` : `height: 150vw;`)}

  ${theme.mediaQueries.small} {
    max-height: 768px;

    ${(props) => (props.condensed ? `height: 590px;` : `height: 768px;`)}
  }
  ${theme.mediaQueries.medium} {
    max-height: 1024px;

    ${(props) => (props.condensed ? `height: 590px;` : `height: 56vw;`)}
  }

  ${(props) =>
    props.fullScreen &&
    `
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
  `}

  ${({ variant }) =>
    variant === 'memDays' &&
    `
    padding-top: 90px;

   ${theme.mediaQueries.medium} {
      padding-top: 120px;
    }
  `}

  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  a {
    text-decoration: none;
  }
`
export const HeroImg = styled(GatsbyImage)`
  ${display}
`
export const HeroImage = styled.div`
  ${display}
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  .gatsby-image-wrapper {
    height: calc(100% + 3px);
  }

  ::after {
    ${background}
  }

  ${({ showOverlay }) =>
    showOverlay &&
    `
    ::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: block;
    }
  `};

  ${({ variant }) =>
    variant === 'memDays' &&
    `
    bottom: 0;
    height: 114%;
    top: auto;

    ${theme.mediaQueries.medium} {
      bottom: auto;
      height: 200%;
      top: calc(-100% + 300px);
    }
  `}
`

export const HeroCopy = styled.div`
  z-index: 1;
  position: relative;
  color: white;
  text-align: center;
  max-width: 780px;
  width: 95%;
  margin: 0 auto;
  opacity: 0;

  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};
`

export const HeroTitle = styled.h1`
  ${({ variant }) =>
    variant === 'memDays' &&
    `
    font-size: ${rem(42)};
    letter-spacing: -.05em;
    margin-left: auto;
    margin-right: auto;
    max-width: 8.3em;

    ${theme.mediaQueries.medium} {
      font-size: ${rem(60)};
    }
  `}

  [data-as="Title32"] {
    display: block;
    font-size: ${rem(26)};
    letter-spacing: -0.05em;

    ${theme.mediaQueries.medium} {
      font-size: ${rem(32)};
    }
  }
`

export const HeroEyebrow = styled.div`
  z-index: 2;
  position: relative;
  color: white;
  text-align: center;
  max-width: 780px;
  width: 95%;
  margin: 0 auto 20px;
  opacity: 0;

  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};
`

export const HeroLogo = styled.div`
  text-align: center;
  z-index: 100;
  display: block;
  position: relative;
  margin: 0 auto;
  opacity: 0;
  max-width: 55vw;
  svg * {
    fill: white;
  }

  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};

  ${theme.mediaQueries.medium} {
    max-width: 35vw;

  ${theme.mediaQueries.large} {
    max-width: 30vw;
  }
`

export const HeroCta = styled.div`
  z-index: 100;
  display: block;
  position: relative;
  opacity: 0;
  margin: 0 auto 20px;
  max-width: 380px;
  text-align: center;

  ${(props) =>
    props.isLink
      ? `
    a {
      height: 60px;
      display: table;
      padding: 20px 5%;
      margin: 0 auto;
      text-align: center;
      color: white;
      text-transform: uppercase;
      position: relative;

      &:after {
        content: '';
        height: 1px;
        background: white;
        width: 100%;
        top: 100%;
        margin-top: 3px;
        display: block;
      }
    }
  `
      : `
    a {
      height: 60px;
      width: 90%;
      padding: 20px 5%;
      margin: 0 5%;
      text-align: center;
      cursor: pointer;
      outline: none;
    }
  `}

  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};
`

export const HeroBody = styled.div`
  ${({ variant }) =>
    variant === 'memDays' &&
    `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: auto;
    min-height: 43%;

    ${theme.mediaQueries.medium} {
      max-height: 300px;
    }

    ${HeroEyebrow} {
      margin-bottom: ${rem(10)};

      p {
        margin: 0;
      }
    }
  `}
`

export const SearchButtonIcon = styled(SearchIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  html[dir='ltr'] & {
    right: calc(${theme.space[10]} / -2);
  }

  html[dir='rtl'] & {
    left: calc(${theme.space[10]} / -2);
  }

  svg g {
    stroke: currentColor;
  }
`

export const ExploreCue = styled.div`
  bottom: 8%;
  color: ${theme.colors.white};
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;

  @media screen and (min-height: 750px) {
    bottom: calc(16% - 60px);
  }

  &::after {
    border: 1px solid currentColor;
    border-width: 0 3px 3px 0;
    content: '';
    display: block;
    height: 11px;
    margin: 0 auto;
    position: relative;
    transform: rotate(45deg);
    width: 11px;
  }

  p {
    font-size: ${rem(10)};
    letter-spacing: 0.2em;
    margin-bottom: 0.2em;

    ${theme.mediaQueries.medium} {
      font-size: ${rem(12)};
      margin-bottom: ${rem(8)};
    }
  }
`

export const ShareCta = styled(Eyebrow)`
  font-size: ${rem(12)};
`

export const ShareBtn = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: 1px solid currentColor;
  border-radius: 100%;
  box-shadow: none;
  color: inherit;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 auto;
  outline: none;
  padding: 0 2px 0 0;
  white-space: normal;
  width: 40px;

  svg {
    fill: currentColor;
  }

  path {
    fill: none;
    stroke: currentColor;
  }
`

export default {
  HeroWrapper,
  HeroImage,
  HeroCopy,
  HeroEyebrow,
  HeroLogo,
}
