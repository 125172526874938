/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import path from 'path'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import BrandDirectory from 'components/BrandDirectory'
import BrandSearch from 'components/BrandSearch'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import trackGTM from 'utils/trackGTM'
import renderContent from 'utils/componentRenderer'

import constants from '../constants'

const BrandDirectoryPage = (props) => {
  const {
    data: {
      page,
      page: { jsonLdSchema, hero, village, content },
    },
    pageContext: { pageLevel },
  } = props

  trackGTM('Brands', `Brands interaction`, `Brands pageview`, 'brands')

  const { locale } = village.home[0]
  const { villageSlug } = village
  const villageLabels = (village && village.labels && village.labels[0]) || {}
  const pageTypeSlug = (page.pageType && page.pageType.pageTypeSlug) || 'brands'

  const getBrandStatusLabel = (openStatus) => {
    const statuses = {
      [constants.OPENING_STATUS]: villageLabels.openingLabel,
      [constants.CLOSING_STATUS]: villageLabels.closingLabel,
      [constants.CLOSED_STATUS]: villageLabels.closedLabel,
      [constants.REOPENING_STATUS]: villageLabels.reopeningLabel,
    }
    return statuses[openStatus]
  }

  const brands = village.brands
    ? village.brands
        .filter(({ hideBrand }) => hideBrand !== true)
        .map(({ categories, name, slug, openStatus }) => ({
          statusLabel: getBrandStatusLabel(openStatus),
          categories,
          name,
          link: path.join(villageSlug, locale, pageTypeSlug, slug),
        }))
    : null

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        image: heroImage,
        eyebrow: hero.eyebrow,
        content: hero.headline,
        logo: page.brandLogo,
        video: heroVideo,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const hasBrands = brands && brands.length > 0 && !!page.search
  const [showBrandSearch, setShowBrandSearch] = useState(false)

  const toggleBrandSearch = () => {
    setTimeout(
      () => {
        // @ts-ignore
        window.modalIsOpened = !showBrandSearch
        setShowBrandSearch(!showBrandSearch)
      },
      showBrandSearch ? 300 : 0
    )
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && (
        <Hero
          {...heroProps}
          ctaLabel={page.search ? page.search.ctaLabel : ''}
          toggleBrandSearch={toggleBrandSearch}
          isBrandDirectory
        />
      )}

      {hasBrands && (
        <BrandDirectory
          brands={brands}
          villageName={
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : page?.village?.name
          }
          categorySearchLabel={page.search.categorySearchLabel}
          ctaLabel={page.search.ctaLabel}
          resultLabel={page.search.resultLabelSing}
          resultsLabel={page.search.resultLabelPlural}
          toggleBrandSearch={toggleBrandSearch}
          {...page.list}
        />
      )}

      {hasBrands && showBrandSearch && (
        <BrandSearch
          brands={brands}
          villageName={
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : page?.village?.name
          }
          onClose={toggleBrandSearch}
          {...page.search}
        />
      )}

      {renderContent(content, props)}
    </Layout>
  )
}

BrandDirectoryPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string,
  }).isRequired,
}

export const brandDirectoryPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    #  $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $villageSlug: String
    $pageTypeSlug: String
  ) {
    page: contentfulPageTemplateBrandLandingT05(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
      pageType {
        pageTypeSlug: slug
      }
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        brands: page_template_brand_detail_t06 {
          name
          slug
          hideBrand
          openStatus: flags
          categories {
            name
            label
          }
        }
        labels: label_brand_details_lab01 {
          closedLabel
          closingLabel
          openingLabel
          reopeningLabel
        }
        defaultLocale
      }
      hero {
        isCondensed
        opacity
        eyebrow
        headline
        title
        ...heroMediaQuery
      }
      list {
        header
        bodyCopy
        loadMoreCTACopy
        allCategoriesCopy
      }
      search {
        categorySearchLabel
        ctaLabel
        headline
        noResults
        promptText
        resultLabelSing
        resultLabelPlural
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT05
        }
      }
    }

    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }

    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }

    #    featuredOffers: allContentfulEntityOfferEnt05(
    #      filter: {
    #        node_locale: { eq: $nodeLocale }
    #        offerRunFinishDate: { gte: $dateNow }
    #      }
    #      sort: { fields: offerRunFinishDate }
    #    ) {
    #      nodes {
    #        id: contentful_id
    #        location {
    #          locationId: contentful_id
    #        }
    #        eyebrow
    #        headline
    #        description {
    #          description
    #        }
    #        offerDetailUrl
    #        offerExpirationLabel
    #        offerRunStartDate(formatString: "D MMMM")
    #        offerRunFinishDate(formatString: "D MMMM YYYY")
    #        offerImage {
    #          ...heroMediaQuery
    #        }
    #      }
    #    }

    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }

    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default BrandDirectoryPage
